import React from 'react';
import styled from 'styled-components';
import {Input, Button} from 'antd';

import * as AppContext from '../../AppContext';

function SettingsPage(props) {
  const app = React.useContext(AppContext.Context);
  const staff = app.state.staff;
  const [values, setValues] = React.useState({
    password: '',
    newPassword: '',
    confirmPassword: '',
  });

  const getValue = (key) => values[key];

  const onValueChange = (key) => (e) => {
    setValues({...values, [key]: e.target.value});
  };

  const onValuesAllClean = () => {
    setValues({password: '', newPassword: '', confirmPassword: ''});
  };

  function formValidation() {
    if (!values.password) {
      alert('請輸入密碼');
      return false;
    } else if (!values.newPassword) {
      alert('請輸入新密碼');
      return false;
    } else if (!values.confirmPassword) {
      alert('請輸入確認新密碼');
      return false;
    } else if (values.newPassword !== values.confirmPassword) {
      alert('新密碼與確認新密碼不一致');
      return false;
    }
    return true;
  }

  async function onChangePassword() {
    if (!formValidation()) {
      return;
    }

    app.actions.setLoading(true);
    try {
      const {password, newPassword} = values;
      await app.actions.staffResetPassword({password, newPassword});
      onValuesAllClean();
      alert('修改成功!');
    } catch (err) {
      alert(err);
    }

    app.actions.setLoading(false);
  }

  return (
    <Wrapper>
      <div className="content">
        <section>
          <h3>Hi {staff.name}，您好！：）</h3>
          <div>以下是您的帳號資訊：</div>
          <div className="color-block">
            <p>ID：{staff.owner}</p>
            <p>姓名：{staff.name}</p>
            <p>電郵：{staff.email}</p>
          </div>
        </section>

        <section>
          <h3>修改密碼</h3>
          <div>
            <label>密碼</label>
            <Input.Password
              value={getValue('password')}
              onChange={onValueChange('password')}
            />
          </div>

          <div>
            <label>新密碼</label>
            <Input.Password
              value={getValue('newPassword')}
              onChange={onValueChange('newPassword')}
            />
          </div>

          <div>
            <label>確認密碼</label>
            <Input.Password
              value={getValue('confirmPassword')}
              onChange={onValueChange('confirmPassword')}
            />
          </div>
        </section>

        <div className="action-bar">
          <Button type="dashed" onClick={() => onValuesAllClean()}>
            重設
          </Button>
          <Button type="primary" onClick={() => onChangePassword()}>
            儲存
          </Button>
        </div>
      </div>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  padding: 72px 40px;
  background-color: white;

  & > .content {
    max-width: 600px;
    margin: 0 auto;

    & > section {
      margin-bottom: 40px;

      & > h3 {
        font-size: 20px;
        color: #4a4a4a;
        margin: 0px 0px 23px 0px;
      }

      & > div {
        margin-bottom: 15px;
        display: flex;
        align-items: center;

        & > label {
          width: 100px;
          color: #4a4a4a;
        }

        & > input {
          flex: 1;
        }
      }

      & > div.color-block {
        flex-direction: column;
        align-items: flex-start;
        background-color: #eaeaea;
        padding: 15px;

        & > p {
          margin-bottom: 5px;
        }
      }
    }

    & > .action-bar {
      padding: 40px 0px;
      display: flex;
      justify-content: flex-end;

      & > button {
        margin-right: 15px;
      }

      & button:last-child {
        margin-right: 0px;
      }
    }
  }
`;

export default SettingsPage;
